#nohello {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  html {
    overflow-x: hidden;
  }

  :root {
    /* --background-primary: #9d8189;
    --background-secondary: #d8e2dc;
    --background-primary-2: #9d8189;
    --background-secondary-2: #d8e2dc;
    --text-color: white; */
    --gradient: rgba(0, 0, 0, 0.5);
  }
  
  #no-text,
  #no-call {
    width: 100%;
    height: 100vh;
    min-height: 20em;
    display: flex;
  }
  #footer-page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 3em;
    background-color: #000814;
    gap: 1em;
  }
  
  #no-text .do-this,
  #no-call .do-this,
  #no-text .dont-do-this,
  #no-call .dont-do-this {
    flex: 1;
    padding: 1em;
  }

  section > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  section > div:nth-child(3) {
    height: auto;
  }
  
  #no-text .do-this {
    background: linear-gradient(rgba(255, 255, 255, 0), var(--gradient)), var(--background-primary);
    color: var(--text-color);
  }
  
  #no-text .dont-do-this {
    background: linear-gradient(rgba(255, 255, 255, 0), var(--gradient)), var(--background-secondary);
    color: var(--text-color);
  }
    
  #no-call .do-this {
    background: linear-gradient(rgba(255, 255, 255, 0), var(--gradient)), var(--background-primary-2);
    color: var(--text-color);
  }
  
  #no-call .dont-do-this {
    background: linear-gradient(rgba(255, 255, 255, 0), var(--gradient)), var(--background-secondary-2);
    color: var(--text-color);
  }
  
  
  ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
  }

  .heading {
    height: 15%;
  }

  .chat-section {
    height: auto;
    padding: 1em;
  }

  .points {
    padding: auto 1em;
  }

  h1, h2, h3 {
    margin: 0;
    text-align: center;
  }
  h1 {
    font-size: 2.5em;
  }
  h2, h3 {
    font-size: 1.5em;
  }

  .share-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
    flex-direction: column;
    width: 100%;
  }

  /* Media query for mobile */
  @media screen and (max-width: 600px) {
      #no-text,
      #no-call {
          flex-direction: column;
          height: auto;
          font-size: 1.5em;
      }

      #footer-page {
          height: auto;
      }
  }


  .custom-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 5em;
    width: 80%;
    padding: 2em;
  }

  .customLink > .root {
    opacity: 0.8;
  }

  .explanation {
    width: 70%;
  }

  .relative {
    position: relative;
  }

  .personal-links {
    opacity: 0.9;
  }
  .logo {
    width: 1.2em;
    height: 1.2em;
    margin: 0.5em;
  }

.title-popover > p {
  position: fixed;
  font-size: 0.6em;
  font-style: italic;
  width: max-content;
  display: none;
  margin-bottom: 0;
  padding: 5px 8px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px #00000021;
  color: #000;
  line-height: 1.4;
  background: #fff;
  border-radius: 1px;
  z-index: 99;
}
.title-popover:hover > p {
  display: block;
}

  /* For mobile */
@media (max-width: 650px) {
  .explanation {
    width: 90%;
    font-size: 1.2em;
  }

  .points {
    margin-bottom: 1em;
  }
  .heading {
    margin: 0.3em;
  }
}