#goodbye {
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black"><text x="0" y="20" font-size="20">⭐</text></svg>'), auto;
   
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    --s: 100px; /* control the size */
    --c1: #00203d;
    --c2: #001328;
    
    --_g: 
        var(--c2) 6%  14%,var(--c1) 16% 24%,var(--c2) 26% 34%,var(--c1) 36% 44%,
        var(--c2) 46% 54%,var(--c1) 56% 64%,var(--c2) 66% 74%,var(--c1) 76% 84%,var(--c2) 86% 94%;
    background:
        radial-gradient(100% 100% at 100% 0,var(--c1) 4%,var(--_g),#0008 96%,#0000),
        radial-gradient(100% 100% at 0 100%,#0000, #0008 4%,var(--_g),var(--c1) 96%)
        var(--c1);
    background-size: var(--s) var(--s);
      
}

#goodbye #first-para {
    width: 90%;
    text-align: center;
    padding: 2rem;
}

@keyframes vibrate {
    0% { transform: translateX(0); }
    20% { transform: translateX(-5px); }
    40% { transform: translateX(5px); }
    60% { transform: translateX(-5px); }
    80% { transform: translateX(5px); }
    100% { transform: translateX(0); }
  }

#goodbye #first-para h1 { 
    color: #ffffff;
    animation: vibrate 1s ease-in-out;
    animation-delay: 2s;
}

#goodbye #contact-options {
    width: 70%;
    margin: 2rem 1rem;
    color: #001328;
    background-color: #ffd60a;
    padding: 2rem;
    border: 1px solid #001328;
    border-radius: 5px;
    cursor: default;
}

#goodbye ul {
    padding: 0 2rem;
}

#goodbye #get-contact {
    margin: 2rem 0;
    font-size: 1.1rem;
    padding: 1rem;
    border-radius: 5px;
    font-family: monospace;
    cursor: default;
}

#goodbye #contact-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
}

#contact-input select#contact-type, #contact-input input#contact-value, #contact-input button { 
    font-size: 1.2rem;
    padding: 0.3rem;
    font-family: monospace;
    
}

#gb-footer-monitor {
    font-size: 0.9rem;
    opacity: 0.5;
    margin: 2rem;
    font-size: 1rem;
}



#goodbye a, #goodbye .highlight {
    color: #004c94;
    font-style: italic;
    font-weight: bold;
}
#goodbye a:hover, #goodbye .highlight:hover {
    color: #001328;
}


#goodbye a {
    text-decoration: underline;
}
.gb-commentary {
    font-size: 0.9rem;
}

#team-img {
    width: 70%;
}
#img-caption-mobile {
    display: none;
}
#img-caption-monitor {
    display: inline;
    width: 70%;
    opacity: 0.8;
    font-style: italic;
    font-size: 1rem;
    text-align: center;
}


@media (max-width: 650px) {
    #goodbye #first-para {
        font-size: 1.2rem;
    }

    #goodbye #contact-options {
        font-size: 1rem;
        width: 70%;
        margin: 1rem 0.5rem;
        color: #001328;
        background-color: #ffd60a;
        padding: 1rem;
        border: 1px solid #001328;
        border-radius: 5px;
    }

    #goodbye ul {
        padding: 0 1rem;
    }
    .gb-commentary {
        font-size: 0.8rem;
        opacity: 0.9;
    }
    #gb-footer-monitor, #img-caption-monitor {
        display: none;
    }
    #img-caption-mobile {
        display: inline;
        width: 70%;
        opacity: 0.8;
        font-style: italic;
        font-size: 0.8rem;
        text-align: center;
    }
}