@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,700&display=swap');



#blog-section {
    background-color: #282c34;
    margin-bottom: 3em;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#notifications-sign-up {
    z-index: 99;
    display: flex;
    flex-direction: column;
    margin: 0 2em;
    padding: 0.5em 1em;
    font-size: 0.8em;
    font-family: monospace;
    background-color: #c0eb75;
    opacity: 1;
    border-radius: 5px;
    color: black;
    width: 50%;
    opacity: 0.9;
}

#notification-type {
    font-family: monospace;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
#notification-input {
    font-family: monospace;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

#notification-input__label {
    padding: 0 0.5em 0 0;
}

#notification-inputs {
    display: flex;
    justify-content: center;
    margin: 0.5em 0;
}

#sign-up-button {
    font-family: monospace;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #66a80f;
    color: white;
    cursor: pointer;
    margin: 0 0.5em;
}

#sign-up-button:hover {
    background-color: #5c940d;
}

#sign-down-button {
    font-family: monospace;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #a80f0f;
    color: white;
    cursor: pointer;
}
#sign-down-button:hover {
    background-color: #940d0d;
}

#rick-dialogue {
    font-size: 0.8em;
    padding: 0.5em;
    color: #2e4b06;
    text-align: center;
}

/* List */

#blog-list {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    margin: 1em;
}
.blog-list__element {
    background-color: black;
    border-radius: 3px;
    margin: 0.5em 0;
    padding: 2em 0.7em 0.7em 0.7em;
    display: flex;
    flex-direction: column;
}
.blog-list__element:hover {
    transition: transform 0.2s ease-in-out;
    transform: scale(1.01);    
    box-shadow: 0.3em 0.3em grey;
}
.blog-list__top-bar {
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.blog-list__title {
    font-family: 'Poppins', sans-serif;
    margin: -0.5em 0 0 0.5em;
    color: rgb(235, 211, 0);
    font-size: 1.7em;
}
.blog-list__desc {
    color: white;
    font-family: monospace;
    font-size: 0.9em;
    padding: 0.5em 1em;
}
.blog-list__subtext {
    color: white;
    font-size: 0.9em;
    font-style: italic;
    margin: 0.5em;
    opacity: 0.8;
}
#blog-divider {
    margin: 2em 0 0em 0;
}
.small-font {
    font-size: 1em;
}

.blog-article__portal {
    display: flex;
    justify-content: center;
}

.blog-list__date {
    font-family: monospace;
    font-size: 0.7em;
    color: white;
    opacity: 0.6;
    text-align: right;
}

@media (max-width: 650px) {
    .blog-section {
        width: 95%;
        margin: 0.5em;
        font-size: 1.2em;
    }
    .blog-list__desc {
        font-size: 1.1em;
    }

    #notifications-sign-up {
        font-size: 1.1em;
        width: 70%;
    }

    #notification-type {
        width: 100%;
    }

    #notification-input {
        width: calc(100% - 20px);
    }

    #notification-inputs {
        flex-direction: column;
        align-items: center;
    }

    #sign-up-button {
        margin: 0.5em;
    }

    #sign-down-button {
        margin: 0.5em;
    }

    #rick-dialogue {
        font-size: 1em;
    }
}