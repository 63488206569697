.ImageTitledCard {
    background-color: rgb(97, 97, 97);
    background-color: black;
    margin: 4em;
    padding: 10px 20px;
    color: rgb(218, 218, 218);
    border-radius: 5px;
    z-index: 2;
}

.ImageTitledCard__img {
    top: -2.3em;
    width: 45%;
    max-width: 20em;
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
}
.ImageTitledCard__desc {
    margin-top: -4em;
    text-align: center;
    font-family: monospace;
    font-size: 0.8em;
    padding: 1em;
}
.tag {
    background-color: rgb(75, 75, 75);
    padding: 0.3em 0.4em;
    margin: 0.5em;
    font-size: 0.8em;
    border-radius: 5px;
}
.ImageTitledCard__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-style: italic;
}
.ImageTitledCard__cta a {
    font-weight: bold;
    background-color: #282C34;
    border-radius: 2px;
    border: 0.25em solid #94968F;
    color: rgb(255, 225, 0, 0.9);
    text-decoration: none;
    padding: 0.5em;
    text-decoration: underline;
}
.ImageTitledCard__cta {
    font-size: 0.7em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0.5em;
}


h2 {
    color: white; 
    font-family: 'Poppins';
}
.ImageTitledCard.projects h2 {
    margin: 0.2em;
}
.ImageTitledCard.projects {
    margin: 0;
}
.ImageTitledCard__img.projects {
    max-width: 5em;
}
.ImageTitledCard__img.projects.electronVLog {
    top: -4em;
    width: 60%;
    max-width: 17em;
    margin-bottom: -6em;
}
.ImageTitledCard__img.projects.noHello {
    border-radius: 5px;
    font-size: 1em;
}
.ImageTitledCard__footer {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: flex-end;
}
.ImageTitledCard__sourceCode {
    max-height: 1.5em;
    margin: 0.5em;
}

@media only screen and (max-width: 1024px) {
    .ImageTitledCard__img.projects.electronVLog {
        width: 80%;
    }
}

@media only screen and (max-width: 650px) {
    .ImageTitledCard {
        margin: 2em 1.5em;
        padding: 1.5em 1.4em;
    }
    .ImageTitledCard__img {
        top: -3.5em;
    }
    .ImageTitledCard__desc {
        font-size: 1.2em;
    }
    .ImageTitledCard__img.projects.electronVLog {
        width: 110%;
        top: -6em;
        margin-bottom: -8em;
    }
    .ImageTitledCard__sourceCode {
        max-height: 2.5em;
    }
    .ImageTitledCard__cta {
        font-size: 1.5em;
    }
    .tag { 
        font-size: 1.2em;
    }
}