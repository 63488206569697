.competition {
    width: 100%;
    background-color: black;
    print-color-adjust: exact;
    padding: 1em;
}
.competition-name {
    font-size: 2em;
    font-weight: bold;
    color: white;
    text-align: center;
    margin: 0.5em;
}
.matches-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
    padding: 20px;
}
.match-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    align-items: flex-start;
    background-color: rgb(0, 99, 221);
    border-radius: 10px;
    margin: 0.5em;
}
.match {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 2px;
    gap: 1em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.match-time {
    font-size: 0.7em;
    font-weight: bold;
    background-color: rgb(76, 76, 76);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 0.2em;
}
.teams {
    display: flex;
    align-items: center;
}
.vs {
    font-size: 0.5em;
    display: flex;
    align-items: center;
    margin: 0 0.5em;
}
.match-tbd {
    opacity: 0.7;
}
.match-completed.match {
    /* background-color: rgba(0, 0, 0, 0.1); */
    border-radius: 10px;
    opacity: 0.7;
    /* add strikethrough */
    text-decoration: line-through;
}
.competition-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.competition-logo {
    width: 10em;
}
.match-date {
    font-size: 0.8em;
    font-family: 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.next-match {
    color: rgba(255, 215, 0, 1);
}

#competitions-list > li {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

#competitions-list > li > a {
    color: white;
    text-decoration: none;
    padding: 0.5em;
    border-radius: 5px;
    margin: 0.5em;
    background-color: rgb(0, 99, 221);
}

#competitions-list > li > .match-pdf-download {
    font-size: 0.7em;
    height: fit-content;
    background-color: rgb(49, 81, 121);
}

#competitions-footer {
    /* display at the absolute bottom */
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
    background-color: rgba(0, 50, 110, 0.1);
    display: flex;
    justify-content: center;
    font-size: 0.7em;
    font-family: monospace;
    opacity: 0.6;
}

/* Competition specific changes */
#logo-copaAmerica24 {
    width: 20em;
}
.match-time.copaAmerica24 {
    background-color: #fd2a2a;
    color: #1f2369;
}

.competition.euros24 {
    background-color: #002c92;
}
.match-time.euros24 {
    background-color: #c1ecff;
    color: #1f2369;
}

@media (max-width: 600px) {
    .matches-container {
        grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
        font-size: 1.5em;
    }
}