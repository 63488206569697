body {
  background-color: black;
  font-size: 0.9vw;
}
.container {
  height: 20em;
  position: relative;
  left: -13em;
}
.title {
  position: absolute;
  text-align: center;
  font-family: 'Get Schwifty';
  font-size: 4em;
  color: #08BAE3;
  z-index: 50;
  -webkit-text-stroke: 1px rgba(0,0,0,0.5);
}
#portal-text {
  position: absolute; 
  left: 10em;
  top: 7em;
}
.title span {
    font-size: 0.5em;
  }

.title.middle {
  -webkit-text-stroke: 0.1em #FFF765;
  z-index: 49;
}

.title.bottom {
  -webkit-text-stroke: 0.4em black;
  z-index: 48;
}

@keyframes portal {
  0% {
    transform: rotate(0deg);
    box-shadow: 0px 0px 40px 10px #448C3F;
  }
  25% {
    box-shadow: 0px 0px 10px 3px #448C3F;
  }
  50% {
    transform: rotate(180deg);    
    box-shadow: 0px 0px 40px 10px #448C3F;
  }
  73% {  
    box-shadow: 0px 0px 10px 3px #448C3F;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0px 0px 40px 10px #448C3F;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);    
  }
  100% {
    transform: rotate(360deg);
  }
}
.portal {
  animation: rotate 180s infinite linear;
  position: absolute;
  height: 25em;
  width: 25em;
  left: 50%;
  border-radius: 50%;
  margin: auto;
}

@media (max-width: 650px) {
  .portal {
    height: 25em;
    width: 25em;
  }
  .title {
    font-size: 4em;
  }
  .container {
    height: 25em;
    margin: 2em;
    left: -12em;
  }
  #portal-text {
    position: absolute; 
    left: 7em;
    top: 4em;
  }
}