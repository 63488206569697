.hiring-url {
  color: #57dada;
  text-decoration: none;
}
.hiring-gif {
  max-width: 80%;

} 

#hiring-container {
  display: flex;
  padding: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black"><text x="0" y="20" font-size="20">⭐</text></svg>'), auto;
} 

.hiring-footer {
    margin: 5em 0;
    font-style: italic;
    text-align: right;
    font-size: 0.8em;
}
.wordart-superhero {
    font-family: Arial, sans-serif;
    font-size: 4em;
    font-weight: bold;
    position: relative;
    z-index: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: skew(0, -15deg) scale(1, 1.5);
}

.wordart-superhero__text span {
    display: inline-block;
    animation: wave 1s infinite;
    background: linear-gradient(to bottom, #fdea00 0%, #fdcf00 44%, #fc2700 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Impact;
}

/* Add delay for each letter */
.wordart-superhero__text span:nth-child(1) { animation-delay: 0.0s; }
.wordart-superhero__text span:nth-child(2) { animation-delay: 0.1s; }
.wordart-superhero__text span:nth-child(3) { animation-delay: 0.2s; }
.wordart-superhero__text span:nth-child(4) { animation-delay: 0.3s; }
.wordart-superhero__text span:nth-child(5) { animation-delay: 0.4s; }
.wordart-superhero__text span:nth-child(6) { animation-delay: 0.5s; }
.wordart-superhero__text span:nth-child(7) { animation-delay: 0.6s; }
.wordart-superhero__text span:nth-child(8) { animation-delay: 0.7s; }

@keyframes wave {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}