.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #b8c1ec;
    color: #232946;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 2em;
    z-index: 9999;
}

.conf-btn {
    padding: 0.5em;
}
  
#canvas-container > canvas {
    max-width: 20em;
}

#confessions-options {
    display: flex;
    flex-direction: column;
}

.confessions-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#confessions-actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
}

.conf-textarea {
    background-color: #bbe3ee;
}

#sentiment-name {
    font-size: 0.8em;
    font-style: italic;
}