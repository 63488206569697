.Quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}
.Quote__Header {
    font-size: 2em;
}
.Quote__Content {
    font-size: 1em;
    font-style: italic;
}
