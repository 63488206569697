#footer {
    display: flex;
    font-size: 0.8em;
    color: rgb(126, 126, 126);
}

#visitors-count {
    padding-bottom: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#visitors-count__number {
    font-weight: bold;
}

/* Display "powered by" while hovering over the footer */
#powered-by a:any-link {
    color: rgb(137, 137, 226);
    font-style: italic;
    opacity: 0;
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear;
    -ms-transition: opacity 0.5s linear;
}
#visitors-count:hover #powered-by a:any-link { 
    opacity: 1;
}

.yellow {
    color: rgb(255, 230, 0);
}
/* https://codepen.io/dmytrous/pen/vgoWxY */
.shake {
    animation: shake-animation 4s ease;
}
@keyframes shake-animation {
    0%  { opacity: 0;}
    50%  { opacity: 0;}
    70% { opacity: 1.0; transform: rotateZ(0deg);}
    72% { opacity: 0.4; transform: rotateZ(1deg);}
    74% { opacity: 0.4; transform: rotateZ(-1deg);}
    76% { transform: rotateZ(0deg);}
    80% { opacity: 1.0; }
 }

 @media (max-width: 650px) {
    #footer {
        font-size: 1.2em;
    }
    #powered-by a:any-link {
        opacity: 0.8;
    }
}