.fixed-footer-container {
    position: fixed;
    bottom: 3em; /* Adjust this value as needed */
    width: 100%;
    z-index: 9999;
    display: flex;
    left: 0px;
    align-items: center;
    justify-content: center;
}


/* For mobile */
@media (max-width: 650px) {
    .fixed-footer-container {
        bottom: 4em;
    }
}