:root {
    --input-color: #2e3035;
    --input-border: #CDD9ED;
    --input-background: #fff;
    --input-placeholder: #CBD1DC;
    --input-border-focus: #275EFE;
    --group-color: var(--input-color);
    --group-border: var(--input-border);
    --group-background: var(--shadow-light);
    --group-color-focus: #fff;
    --group-border-focus: var(--input-border-focus);
    --group-background-focus: #678EFE;
    --correct: #00C48C;
    --incorrect: #FF647C;
  }
  
  .form-field {
    display: block;
    width: 10em;
    padding: 8px 16px;
    line-height: 25px;
    font-weight: 500;
    font-size: 1em;
    font-family: inherit;
    border-radius: 6px;
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border 0.3s ease;
  }
  .form-field::-moz-placeholder {
    color: var(--input-placeholder);
  }
  .form-field:-ms-input-placeholder {
    color: var(--input-placeholder);
  }
  .form-field::placeholder {
    color: var(--input-placeholder);
  }

  .form-field.correct:focus {
    outline: none;
    border-color: var(--correct);
  }
   .form-field.incorrect:focus {
    outline: none;
    border-color: var(--incorrect);
  }
  
  .form-group, #surprise-me {
    position: relative;
    display: flex;
    margin-top: 1em;
  }
  .form-group-container {
    display: flex;
  }
  .form-group > span,
  .form-group .form-field {
    white-space: nowrap;
    display: block;
  }
  .form-group > span:not(:first-child):not(:last-child),
  .form-group .form-field:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .form-group > span:first-child,
  .form-group .form-field:first-child {
    border-radius: 6px 0 0 6px;
  }
  .form-group > span:last-child,
  .form-group .form-field:last-child {
    border-radius: 0 6px 6px 0;
  }
  .form-group > span:not(:first-child),
  .form-group .form-field:not(:first-child) {
    margin-left: -1px;
  }
  .form-group .form-field {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-group > span {
    text-align: center;
    padding: 8px 12px;
    line-height: 25px;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    font-size: 1em;
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  }
  
  .form-group.correct > span {
    color: var(--group-color-focus);
    background: var(--correct);
    border-color: var(--correct);
  }
  .form-group.incorrect > span {
    color: var(--group-color-focus);
    background: var(--incorrect);
    border-color: var(--incorrect);
  }


  .link-condition.correct {
    color: var(--correct);
  }
  .link-condition.incorrect {
    color: var(--incorrect);
  }
  .form-group > .copy-button {
    cursor: pointer;
    background-color: var(--shadow-dark);
    border-radius: 0px 5px 5px 0px !important;
  }
  .copy-button:hover {
    background-color: var(--shadow-light);
  }

  #surprise-img {
    height: 1.5em;
  }
  #surprise-me {
    color: white;
    font-weight: 500;
    font-size: 1em;
    text-align: center;
    background-color: var(--shadow-dark);
    display: flex;
    justify-content: center;
    justify-self: center;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin-left: 1em;
    padding: 0em 0.5em;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid rgba(255, 0, 0, 0);
  }
  #surprise-me:hover {
    background-color: var(--shadow-light);
  }
  /* when surprise-me is clicked, show a gold border */
  #surprise-me:active {
    border: 1px solid rgb(255, 255, 255);
  }

  .link-examples {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .link-examples.right {
    align-items: flex-start;
  }

  /* For mobile */
@media (max-width: 650px) {
  .form-group-container {
    flex-direction: column;
    align-items: center;
  }
  .form-group > span {
    line-height: normal;
  }
  .form-field { 
    padding: 0px 16px;
  }
  #surprise-img {
    height: 2em;
  }
  #surprise-me {
    width: max-content;
    padding: 0.5em;
  }
}