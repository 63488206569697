#possible-fonts {
    background-color: #232946;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1 {
    font-color: #fffffe;
}

table {
    width: 80%;
}

td {
    max-width: 20em; /* Adjust the maximum width as needed */
    word-wrap: break-word;
    overflow-y: auto;
}
td:first-child {
    width: max-content;
    font-size: 0.8em;
}
  
#font-entries {
    border-collapse: separate;
    border-spacing: 0;
}

#font-entries tr {
    border-bottom: 2px solid #b8c1ec;
}

.font-name {
    background-color: #b8c1ec;
    color: #232946;
    padding: 0.5em 1em;
    border-radius: 1em; /* Adjust border-radius value as needed */
    cursor: pointer;
    display: inline-block;
}
.copy-symbol {
    font-size: 1.2em;
    color: #eebbc3;
    margin-right: 0.5em;
}
.clickable {

}
.font-column {
    display: flex;
    max-width: 12em;
    margin-right: 1em;
}

textarea {
    width: 70%;
    font-size: 1.2em;
    height: 5em; /* Adjust the height as needed */
    border-radius: 8px;
    background-color: #eebbc3;
    color: #232946;
    padding: 10px;
    margin: 1em;
}

.toast {
    /* position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #b8c1ec;
    color: #232946;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 9999; */
}
  