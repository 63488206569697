#work-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.work-entry {
    z-index: 2;
}
.caption, #divider {
    transform: rotateZ(-3deg);
}
.direction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#divider {
    border-top: 0.2em solid rgba(255, 255, 234, 0.292);
    width: 4em;
}
.caption {
    font-size: 1.5em;
    font-family: 'Poppins';
    color: rgb(255, 255, 234);
    margin: 1em;
}
#work-caption {
    margin: 0 auto;
}
#projects-caption {
    margin-top: 0;
}
#arrow-top {
    width: 8em; 
    height: 10em;  
    border: solid 5px rgba(255, 255, 234, 0.5);
    border-color: rgba(255, 255, 234, 0.5) transparent transparent transparent;
    border-radius: 50%/100px 100px 0 0;
    transform: rotateZ(120deg);
    margin: -8em auto 0em auto;
}
#arrow-bottom {
    width: 8em; 
    height: 10em;  
    border: solid 5px rgba(255, 255, 234, 0.5);
    border-color: rgba(255, 255, 234, 0.5) transparent transparent transparent;
    border-radius: 50%/100px 100px 0 0;
    transform: rotateZ(-120deg);
    margin: -5em auto -2em auto;
}

#zoho-container {
    margin-top: -3em;
}

@media (max-width: 650px) {
    #zoho-container {
        margin-top: 0em;
    }
}