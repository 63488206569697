.toast {
    background-color: #000000;
    color: #c8a910;
    padding: 0.5em 1em;
    border-radius: 8px;
    z-index: 9999;
    border: #c8a910 3px solid;
    max-width: 50%;
    text-align: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
}

  

/* For mobile */
@media (max-width: 650px) {
    .toast {
        max-width: 70%;
        border: #c8a910 5px solid;
        font-size: 0.7em;
        width: max-content;
    }
}