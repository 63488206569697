#projects {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
}
#projects-container {
    display: flex;
    flex: 0 0 5em;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.project {
    border: 0.5em;
    border-color: rgb(97, 97, 97);
    border-style: solid;
    margin: 2em;
    max-width: 40%;
    border-radius: 3px;
}

a {
    text-decoration: none;
}

footer {
    margin: 1em;
    font-style: italic;
    opacity: 0.7;
}
@media only screen and (max-width: 1024px) {
    .project {
        max-width: 70%;
    }
}

@media only screen and (max-width: 650px) {
    .project {
        max-width: 100%;
    }
    footer {
        font-size: 1.1em;
    }
}