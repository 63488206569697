#about-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

/* https://w3bits.com/css-masonry/ */
.about-reviews-container {
    margin: 0 2em;
    max-width: 50em;
    padding: 0.5em;
    text-align: center;
    border-radius: 5px;
    white-space: pre-wrap;
    word-wrap: break-word;
    column-count: 2;
    column-gap: 1em;
    font-size: 0.9em;
}
.about-review-card {
    background-color: black;
    display: inline-block;
    margin: 0.5em 0;
    box-sizing: border-box;
    width: 100%;
}
.about-review-card__tldr {
    margin-top: -0.5em;
    color: rgb(255, 230, 0);
    font-weight: bold;
    font-size: 2em;
    rotate: -2deg;
    font-family: "Satisfy";
}

.about-review-card:hover .about-review-card__tldr {
    text-shadow: 0 0 10px rgba(255, 230, 0, 0.612);
    /* transform: scale(1.01); */
}
.about-review-card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.546);
}

.about-review-card__body {
    padding: 0.5em 1em;
    text-align: left;
}
.about-review-card__author {
    display: flex;
    align-items: center;
    color: white;
    padding: 0.5em 0.5em 1em 0.5em;
}
.about-review-card__author__img {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin: 0 0.5em;
}
.about-review-card__author__info {
    text-align: left;
    padding: 0 0.5em;
}
.about-review-card__author__name {
    font-weight: bold;
}
.about-review-card__author__tagline {
    font-size: 0.7em;
    opacity: 0.8;
    font-weight: lighter;
    font-style: italic;
}

#contact {
    display: grid;
    text-align: left;
    margin: auto;
    max-width: 50%;
    background-color: rgb(255, 230, 0);
    color: black;
    padding: 1em;
    border-radius: 10px;
    word-wrap: break-word;
}
#contact:hover .contact-call-emoji {
    display: inline-block;
    animation: vibrate-emoji 0.5s infinite ease-in-out;
    text-shadow: 0 0 20px rgba(117, 114, 114, 0.302);
}

@keyframes vibrate-emoji {
    0% {
        rotate: 0;
    }
    25% {
        rotate: -5deg;
    }
    50% {
        rotate: 0deg;
    }
    75% {
        rotate: 5deg;
    }
    100% {
        rotate: 0;
    }
}

#email-text {
    text-align: center;
    font-family: monospace;
    margin-bottom: 0.5em;
}

.review-title {
    font-family: monospace;
    background-color: black;
    padding: 0.5em 1em;
    color: rgb(255, 230, 0);
    border-radius: 10px;
    margin: 2em 0 0em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.review-title__stars {
    font-size: 1.5em;
}
.review-title__subtext {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.8;
}
.review-title__subtext_ka_subtext {
    color: white;
}
.review-title:hover .review-title__subtext,
details[open]  {
    opacity: 1;
}

.review-title:hover .review-title__stars {
    text-shadow: 0 0 10px rgba(255, 230, 0, 0.591);
}
.about-review-link {
    color: rgb(35, 119, 204)
}
.about-review-link:hover {
    text-decoration: underline;
}

.icons {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: center;
}
.icon {
    max-height: 1em;
    margin: 0 0.5em;
}
.icons__content {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
}
#twitter-text {
    color: black;
    font-size: 0.8em;
    font-weight: bold;
    margin-right: -0.5em;
    font-family: 'Tahoma';
}
#review-footer {
    font-size: 0.7em;
    font-style: italic;
    opacity: 0.8;
}
.review-footer-tldr {
    font-family: "Satisfy";
    color: rgb(255, 230, 0);
    font-weight: bold;
}

@media (max-width: 650px) {
    #contact {
        max-width: 100%;
    }
    #about-container {
        font-size: 1.3em;
    }
    .about-reviews-container {
        column-count: 1;
    }
    .review-title {
        margin: 2em 2em 0 2em;
        padding-bottom: 1em;
    }
    .about-review-card__author__tagline {
        font-size: 0.9em;
        font-weight: normal;
    }
    #review-footer {
        font-size: 1em;
    }
    
}