*
{
  margin: 0;
  padding: 0;
  outline: none;
}

body
{
  font-family: 'Roboto', sans-serif;
  background: #c1b4ff;
}

:root {
  --shadow-light: #fadf58;
  --shadow-dark: #ffd60a;
  --chat-you: #e7b100;
  --background-primary: #001d3d;
  --background-secondary: #003566;
  --background-primary-2: #001d3d;
  --background-secondary-2: #003566;
  --text-color: white;
}




div.chat-messages div.chat
{
  position: relative;
  width: 20em;
  height: 25em;
  background: #fcfcfe;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;

  box-shadow: 30px 0 0 -15px var(--shadow-light),
              60px 0 0 -30px var(--shadow-dark),
              -30px 0 0 -15px var(--shadow-light),
              -60px 0 0 -30px var(--shadow-dark),
              0 0 25px rgba(255,255,255,0.5);
}

div.chat div.chat-content > span
{
  margin-bottom: 12px;
}

div.chat div.chat-content span.friend
{
  position: relative;
  width: 80%;
  height: auto;
  display: inline-block;
  background: #fff;
  padding: 10px;
  padding-bottom: 2em;
  box-shadow: 2px 2px 20px -2px rgba(60,51,176,0.2);
  color: rgba(60,51,176,1);
}

div.chat div.chat-content span.friend.first
{
  border-radius: 15px 15px 15px 2px;
}

div.chat div.chat-content span.friend.last
{
  border-radius: 2px 15px 15px 15px;
}

div.chat div.chat-content span.friend span.time
{
  position: absolute;
  display: block;
  right: 0;
  margin-top: 5px;
  margin-right: 10px;
  font-weight: 500;
  color: rgba(60,51,176,0.5);
}

div.chat div.chat-content span.you
{
  position: relative;
  float: right;
  width: 80%;
  height: auto;
  background: var(--chat-you);
  padding: 10px;
  padding-bottom: 2em;
  color: #fff;

  box-shadow: 2px 2px 20px rgba(60,51,176,0.2),
              inset -10px -10px 55px rgba(255,255,255,0.1);
}

div.chat div.chat-content span.you span.time
{
  position: absolute;
  display: block;
  right: 0;
  margin-top: 5px;
  margin-right: 10px;
  font-weight: 500;
  color: rgba(255,255,255,0.5);
}

.time {
  font-size: 1em;
}

div.chat div.chat-content span.you
{
  border-radius: 15px 15px 2px 15px;
}

div.chat div.chat-content span.friend
{
  border-radius: 15px 15px 15px 2px;
}

div.msg-box
{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(60,51,176,0.1);
  overflow: hidden;
  display: flex;
  align-items: center;
}

div.msg-box .ip-msg-container {
  width: 100%;
}
div.msg-box .ip-msg
{
  width: 100%;
  font-size: 14px;
  padding: 15px;
  color: rgba(60,51,176,0.9);
  border: none;
  background: rgba(0,0,0,0.03);
}

div.msg-box .ip-msg::placeholder
{
  color: rgba(60,51,176,0.4);
}

.send-icon {
  width: 1.5em;
  height: 1.5em;
  opacity: 0.7;
  cursor: pointer;
  padding: 0.5em;
}







/* Call */
.call-content {
  width: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: right;
  top: -3em;
  right: -7em;
}

.pulse i {
  color: #fff
}

.pulse {
  height: 10em;
  width: 10em;
  background-color: rgba(104, 234, 104, 0.875);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative
}
.pulse.incorrect {
  background-color: rgba(234, 104, 104, 0.875);
}

.pulse::before {
  content: "";
  position: absolute;
  border: 1px solid rgba(104, 234, 104, 0.875);
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite
}
.pulse.incorrect::before {
  border: 1px solid rgba(234, 104, 104, 0.875);
}

.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid rgba(104, 234, 104, 0.875);
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s
}
.pulse.incorrect::after {
  border: 1px solid rgba(234, 104, 104, 0.875);
}

.phone-icon {
  width: 4em;
}

@keyframes pulse {
  0% {
      transform: scale(0.5);
      opacity: 0
  }

  50% {
      transform: scale(1);
      opacity: 1
  }

  100% {
      transform: scale(1.3);
      opacity: 0
  }
}

.event {
  font-style: italic;
  color: red;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event > .time {
  color: gray;
}

.relative {
  position: relative;
}

/* For mobile */
@media (max-width: 650px) {
  div.chat-messages div.chat {
    font-size: 0.8em;
    box-shadow: 20px 0 0 -10px var(--shadow-light),
      40px 0 0 -20px var(--shadow-dark),
      -20px 0 0 -10px var(--shadow-light),
      -40px 0 0 -20px var(--shadow-dark),
      0 0 25px rgba(255,255,255,0.5);
      
    height: max-content;
    padding-bottom: 9em; /* To make space for the type something bar */
  }

  .call-content {
    top: -1em;
    right: -4em;
  }
  .pulse {
    height: 8em;
    width: 8em;
  }
}