#time-translation-container {
	width: 100%;
	height: 100%;
	display: flex;
}
#time-translation {
	width: 100%;
    background-color: black;
}

#selected-time {
    width: 15rem;
    display: flex;
}

#selected-time > * {
    flex-grow: 1;
}

#results {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    font-family: 'Courier New', Courier, monospace;
}

.label-color-red {
    color: red;
}
.label-color-green {
    color: green;
}
.label-color-orange {
    color: orange;
}
.timezone-text {
    opacity: 0.8;
    font-size: medium;
}