.OptionCards {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
}

.OptionCard {
    background-color: rgb(0, 0, 0);
    height: 30%;
    width: 12em;
    margin: 2em;
}
.OptionCard__img-container {
    background-color: black;
}
.OptionCard__img {
    top: -2em;
    width: 70%;
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    filter: brightness(70%);
}
.OptionCard__bottom {
    display: flex;
    flex-direction: column;
    background-color: rgb(97, 97, 97);
    margin-top: 0;
    height: 7em;
    padding: 0 20px;
}
.OptionCard__title {
    text-align: center;
    font-size: 3em;
    font-family: 'Impact', 'Impacto', Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    margin-top: -0.7em;
    color: rgb(255, 230, 0);
    text-decoration: underline;
}
.OptionCard__desc {
    margin-top: 1.3em;
    text-align: center;
    font-family: monospace;
    font-size: 0.8em;
}
/*Animations for OptionCard onHover*/
.OptionCard:hover .OptionCard__img {
    filter: brightness(100%);
}
.OptionCard:hover {
    animation-name: rotate-z;
    animation-duration: 0.5s;
}
@keyframes rotate-z {
    50% {transform: rotateZ(1deg);}
    100% {transform: rotateZ(0deg);}
}

/*Media query for smaller screens*/
@media only screen and (max-width: 40em) {
    .OptionCards {
        flex-direction: column;
        align-items: center;
        font-size: 1.5em;
    }
  }